import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h2>Coming soon...</h2>
        <a href='https://twitter.com/FTMBros'>Twitter</a>
      </header>
    </div>
  );
}

export default App;
